<template>
	<div>
		<CCard class="p-4">
			<CRow>
				<CCol lg="6">
					<h5>Personnel Renewal Reminders</h5>
				</CCol>
				<CCol lg="6">
					<CButton size="sm" style="float: right;"
						:class="'float-lg-right block ' + showCollapse ? 'collapsed' : null"
						:aria-expanded="showCollapse ? 'true' : 'false'" aria-controls="collapse1"
						@click="showCollapse = !showCollapse" color="info"><i class="fa fa-search"></i>Filter<i
							:class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
					 
					<CButton v-if="config.getPermission('license_renewal').create" size="sm" style="margin-right: 10px"
						color="success" href="#/data/create_license_renewal" target="_blank" class="float-lg-right"><i
							class="fa fa-plus"></i>Add Personnel Renewal Reminder
					</CButton>
					<CButton 
						v-if="config.getPermission('license_renewal').download"
						size="sm" style="margin-right: 10px" 
						color="primary" @click="download" 
						class="float-lg-right"><i class="fa fa-download"></i>Download
					</CButton>
				</CCol>
			</CRow>
			<CRow>
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
						<CCard class="p-4">
							<Search @depot-search-query="search" />
						</CCard>
					</CCollapse>
				</CCol>
			</CRow>
			<hr>
			<CRow>
				<CCol lg="12">
					<div class="table-responsive-sm table-responsive-md">
						<CTabs variant="pills" :active-tab="0">
							<CTab title="All">
								<br />
								<CDataTable :loading="isLoading" :items="license_renewal_list_all.data" :fields="fields" hover striped outlined>
									<template #due_date="{ item }">
										<td style="padding: 5px;">
											{{ item.due_date | date_format('MMMM DD, YYYY')}} <br>
											<CBadge v-if="item.time != null" :color="item.due_date|badgeColor(item.time, item.threshold_unit)">
												<small>{{ item.due_date|badgeText }} </small>
											</CBadge>
										</td>
									</template>
									<template #status="{ item }">
										<td style="padding: 5px; vertical-align: inherit;">
											<CButton size="sm" style="width: 74px;" :color="item.due_date|dueDateStatusColor(item.time,item.threshold_unit)">
												<!-- {{ item.due_date|dueDateStatusTextTest(item.time,item.threshold_unit) }} -->
												{{ item.status }}
											</CButton>
										</td>
									</template>
									<template #action="{ item }">
										<td style="padding: 5px; width: 100px; vertical-align: inherit;">
											<CDropdown toggler-text="Select" size="sm" color="info">
												<CDropdownItem v-if="config.getPermission('license_renewal').view"
													@click="viewRenewal(item)"><i class="fa fa-eye"> View </i>
												</CDropdownItem>
												<CDropdownItem 
												v-if="config.getPermission('license_renewal').update"
														@click="updateRenewal(item)"><i
														class="fa fa-pencil-square-o"> Update </i></CDropdownItem>
												<CDropdownItem @click="sendEmail(item)">
													<div>
														<div><i class="fa fa-bell"> Notify </i></div>
													</div>
												</CDropdownItem>
												<CDropdownItem
												 v-if="config.getPermission('license_renewal').delete"
												 @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i>
												</CDropdownItem>
											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<label style="float: right;t">Showing {{ license_renewal_list_all.from }} to {{ license_renewal_list_all.to }} of {{ license_renewal_list_all.total }} results</label>
								<CPagination
						          	:active-page.sync="currentPageAll"
						          	:pages="Math.ceil(countDataAll / 20)"
						          	:activePage="currentPageAll"
									  @update:activePage="updatePageAll"
						        />

								<!-- <CPagination :active-page.sync="currentPageAll" :pages="Math.ceil(dataListAll.total / 20)"
							:activePage="currentPageAll" @update:activePage="updatePageAll" /> -->
								
							</CTab>					
							<CTab title="Due Soon">
								<br />
								<CDataTable :loading="isLoading" :items="license_renewal_list_due_soon.data" :fields="fields" hover striped outlined>
									<template #due_date="{ item }">
										<td style="padding: 5px;">
											{{ item.due_date | date_format('MMMM DD, YYYY')}} <br>
											<CBadge v-if="item.time != null" :color="item.due_date|badgeColor(item.time, item.threshold_unit)">
												<small>{{ item.due_date|badgeText }} </small>
											</CBadge>
										</td>
									</template>
									<template #status="{ item }">
										<td style="padding: 5px; vertical-align: inherit;">
											<CButton size="sm" style="width: 74px;" :color="item.due_date|dueDateStatusColor(item.time,item.threshold_unit)">
												<!-- {{ item.due_date|dueDateStatusTextTest(item.time,item.threshold_unit) }} -->
												{{ item.status }}
											</CButton>
										</td>
									</template>
									<template #action="{ item }">
										<td style="padding: 5px; width: 100px; vertical-align: inherit;">
											<CDropdown toggler-text="Select" size="sm" color="info">
												<CDropdownItem v-if="config.getPermission('license_renewal').view"
													@click="viewRenewal(item)"><i class="fa fa-eye"> View </i>
												</CDropdownItem>
												<CDropdownItem 
												v-if="config.getPermission('license_renewal').update"
														@click="updateRenewal(item)"><i
														class="fa fa-pencil-square-o"> Update </i></CDropdownItem>
												<CDropdownItem @click="sendEmail(item)">
													<div>
														<div><i class="fa fa-bell"> Notify </i></div>
													</div>
												</CDropdownItem>
												<CDropdownItem
												 v-if="config.getPermission('license_renewal').delete"
												 @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i>
												</CDropdownItem>
											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<label style="float: right;t">Showing {{ license_renewal_list_due_soon.from }} to {{ license_renewal_list_due_soon.to }} of {{ license_renewal_list_due_soon.total }} results</label>
								<CPagination
						          	:active-page.sync="currentPageDueSoon"
						          	:pages="Math.ceil(countDataDueSoon / 20)"
						          	:activePage="currentPageDueSoon"
									  @update:activePage="updatePageDueSoon"
						        />
							</CTab>
							<CTab title="Overdue">
								<br />
								<CDataTable :loading="isLoading" :items="license_renewal_list_overdue.data" :fields="fields" hover striped outlined>
									<template #due_date="{ item }">
										<td style="padding: 5px;">
											{{ item.due_date | date_format('MMMM DD, YYYY')}} <br>
											<CBadge v-if="item.time != null" :color="item.due_date|badgeColor(item.time, item.threshold_unit)">
												<small>{{ item.due_date|badgeText }} </small>
											</CBadge>
										</td>
									</template>
									<template #status="{ item }">
										<td style="padding: 5px; vertical-align: inherit;">
											<CButton size="sm" style="width: 74px;" :color="item.due_date|dueDateStatusColor(item.time,item.threshold_unit)">
												<!-- {{ item.due_date|dueDateStatusTextTest(item.time,item.threshold_unit) }} -->
												{{ item.status }}
											</CButton>
										</td>
									</template>
									<template #action="{ item }">
										<td style="padding: 5px; width: 100px; vertical-align: inherit;">
											<CDropdown toggler-text="Select" size="sm" color="info">
												<CDropdownItem v-if="config.getPermission('license_renewal').view"
													@click="viewRenewal(item)"><i class="fa fa-eye"> View </i>
												</CDropdownItem>
												<CDropdownItem 
												v-if="config.getPermission('license_renewal').update"
														@click="updateRenewal(item)"><i
														class="fa fa-pencil-square-o"> Update </i></CDropdownItem>
												<CDropdownItem @click="sendEmail(item)">
													<div>
														<div><i class="fa fa-bell"> Notify </i></div>
													</div>
												</CDropdownItem>
												<CDropdownItem
												 v-if="config.getPermission('license_renewal').delete"
												 @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i>
												</CDropdownItem>
											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<label style="float: right;t">Showing {{ license_renewal_list_overdue.from }} to {{ license_renewal_list_overdue.to }} of {{ license_renewal_list_overdue.total }} results</label>
								<CPagination
						          	:active-page.sync="currentPageOverdue"
						          	:pages="Math.ceil(countDataOverdue / 20)"
						          	:activePage="currentPageOverdue"
									  @update:activePage="updatePageOverdue"
						        />
							</CTab>
						</CTabs>
					</div>
				</CCol>
			</CRow>
		</CCard>
	</div>
</template>
<script>
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';

export default {
	mounted() {
		this.getData('All Status');
		this.getDataDueSoon('Due soon');
		this.getDataOverDue('Overdue');
	},
	data() {
		return {
			config,
			license_renewal_list_all: [],
			license_renewal_list_due_soon: [],
			license_renewal_list_overdue: [],
			fields: [
				{
					key: 'personnel_name',
					label: 'Personnel'
				},
				{
					key: 'license_renewal_type_name',
					label: 'Renewal Type'
				},
				{
					key: 'due_date',
					label: 'Due Date'
				},
				{
					key: 'status',
					label: 'Status',
				},
				{	
					key: 'action',
					label: 'Action',
				}
			],
			filter: {
				personnel: '',
				renewal_task_id: '',
				date_from: '',
				date_to: '',
				company_id: '',
				status:''
			},
			showCollapse: false,
			isLoading: false,
			currentPageAll: 1,
			currentPageDueSoon: 1,
			currentPageOverdue: 1,
		}
	},
	name: 'Tables',
	components: { Search, Datepicker, vSelect },
	methods: {
		updatePageAll(data) {
			this.currentPageAll = data;
			this.getData('All Status');
		},
		updatePageDueSoon(data) {
			this.currentPageDueSoon = data;
			this.getDataDueSoon('Due soon');
		},
		updatePageOverdue(data) {
			this.currentPageOverdue = data;
			this.getDataOverDue('Overdue');
		},
		search(event) {
			this.filter = event;
			this.getData('All Status');
			this.getDataDueSoon('Due soon');
			this.getDataOverDue('Overdue');
		},
		getBadge(status) {
			return status === 'due soon' ? 'warning'
				: status === 'overdue' ? 'danger' : 'primary'
		},
		updateRenewal(item) {
			window.open('/#/data/update_license_renewal/' + item.id, '_blank');
		},
		viewRenewal(item) {
			window.open('/#/data/view_license_renewal/' + item.id, '_blank');
		},
		getData(status) {
			this.license_renewal_list_all = [];
			this.isLoading = true;
			this.filter.company_id = config.global_user_data.company.id;
			this.filter.status = status;
			axios.get(config.api_path + '/license-renewal-reminder?page='+this.currentPageAll, {
				params: this.filter
			}).then(response => {
				// let data = response.data.data;
				// this.license_renewal_list_all = data;
				const {data} = response.data;
				this.license_renewal_list_all = data;
			
				if(!Array.isArray(data.data)) {
					const holder = [];
					for (const key in data.data) {
						if (Object.hasOwnProperty.call(data.data, key)) {
							const element = data.data[key];
							holder.push(element)
						}
					}
					this.license_renewal_list_all.data = [...holder]
				}
				
			}).catch(err => {
				console.error(err)
				this.isLoading = false;
			}).finally(fin => {
				console.log(fin)
				this.isLoading = false;
			})
		},
		getDataDueSoon(status) {
			this.license_renewal_list_due_soon = [];
			this.isLoading = true;
			this.filter.company_id = config.global_user_data.company.id;
			this.filter.status = status;
			axios.get(config.api_path + '/license-renewal-reminder?page='+this.currentPageDueSoon, {
				params: this.filter
			}).then(response => {
				let data = response.data.data;
				this.license_renewal_list_due_soon = data;

				if(!Array.isArray(data.data)) {
					const holder = [];
					for (const key in data.data) {
						if (Object.hasOwnProperty.call(data.data, key)) {
							const element = data.data[key];
							holder.push(element)
						}
					}
					this.license_renewal_list_due_soon.data = [...holder]
				}

			}).catch(err => {
				console.error(err)
				this.isLoading = false;
			}).finally(fin => {
				console.log(fin)
				this.isLoading = false;
			})
		},
		getDataOverDue(status) {
			this.license_renewal_list_overdue = [];
			this.isLoading = true;
			this.filter.company_id = config.global_user_data.company.id;
			this.filter.status = status;
			axios.get(config.api_path + '/license-renewal-reminder?page='+this.currentPageOverdue, {
				params: this.filter
			}).then(response => {
				let data = response.data.data;
				this.license_renewal_list_overdue = data;

				if(!Array.isArray(data.data)) {
					const holder = [];
					for (const key in data.data) {
						if (Object.hasOwnProperty.call(data.data, key)) {
							const element = data.data[key];
							holder.push(element)
						}
					}
					this.license_renewal_list_overdue.data = [...holder]
				}
			}).catch(err => {
				console.error(err)
				this.isLoading = false;
			}).finally(fin => {
				console.log(fin)
				this.isLoading = false;
			})
		},
		deleteRow(item){
			Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to delete this Renewal reminder?', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.post(config.api_path+'/license-renewal-reminder/'+item.id)
			    	.then(response => {
			    		this.getData();  
			    		Swal.fire({
							title: 'Success!',
							text: "Renewal reminder successfully deleted",
							icon: 'success', 
						})
						this.getData('All Status');
						this.getDataDueSoon('Due soon');
						this.getDataOverDue('Overdue');
			    	}) 
				}
			})  
		},
		getLicenseRenewalTask() {
			axios.get(config.api_path + '/reference/settings-list', {
				params: {
					setting_type: 'license_renewal_task'
				}
			})
			.then(response => {
				this.taskList = response.data;
			})
		},
		sendEmail(item) {
			Swal.fire({
				title: 'Sending email/s',
				html: 'Please wait.',
				allowOutsideClick:false,
				allowEscapeKey:false,
			});
			Swal.showLoading();
			axios.post(config.api_path + '/license-renewal-reminder-send-email/' + item.id)
			.then(response => {
				Swal.fire({
					title: 'Success!',
					text: 'Successfully notified ' + response.data.message,
					icon: 'success',
				})
			})
		},
		download(){
			var personnel = this.filter.personnel;
	    	if(personnel == "all" || personnel == null){
	    		personnel = ""
	    	}
			var renewal_task_id = this.filter.renewal_task_id;
	    	if(renewal_task_id == "all" || renewal_task_id == null){
	    		renewal_task_id = ""
	    	} 
 
			let date_from = '';
	    	if(this.filter.date_from != ""){ 
	    		date_from = moment(this.filter.date_from).startOf('day') / 1000;
	    	}
			let date_to = '';
	    	if(this.filter.date_to != ""){ 
	    		date_to = moment(this.filter.date_to).startOf('day') / 1000;
	    		date_to += 86399;
	    	}
			this.$showLoading(true)

	    	axios.get(config.api_path+'/reports/personnel-renewal-reminder?company_id='+config.global_user_data.company_id
				+'&personnel='+personnel
				+'&renewal_task_id='+renewal_task_id
				+'&date_from='+date_from
				+'&date_to='+date_to
				+'&limit=20')
	    	.then(response=>{
				this.$showLoading(false)
				window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
	    	})
			.catch(err => {
				this.$showLoading(false)
			})
		}
	},
	computed: {
		countDataAll() {
			if(!this.license_renewal_list_all.data){
				return 0;
			}
			return this.license_renewal_list_all.total;
		},
		countDataDueSoon() {
			if(!this.license_renewal_list_due_soon.data){
				return 0;
			}
			return this.license_renewal_list_due_soon.total;
		},
		countDataOverdue() {
			if(!this.license_renewal_list_overdue.data){
				return 0;
			}
			return this.license_renewal_list_overdue.total;
		},
	}
}
</script>
