var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{staticClass:"p-4"},[_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('h5',[_vm._v("Personnel Renewal Reminders")])]),_c('CCol',{attrs:{"lg":"6"}},[_c('CButton',{class:'float-lg-right block ' + _vm.showCollapse ? 'collapsed' : null,staticStyle:{"float":"right"},attrs:{"size":"sm","aria-expanded":_vm.showCollapse ? 'true' : 'false',"aria-controls":"collapse1","color":"info"},on:{"click":function($event){_vm.showCollapse = !_vm.showCollapse}}},[_c('i',{staticClass:"fa fa-search"}),_vm._v("Filter"),_c('i',{class:_vm.showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'})]),(_vm.config.getPermission('license_renewal').create)?_c('CButton',{staticClass:"float-lg-right",staticStyle:{"margin-right":"10px"},attrs:{"size":"sm","color":"success","href":"#/data/create_license_renewal","target":"_blank"}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("Add Personnel Renewal Reminder ")]):_vm._e(),(_vm.config.getPermission('license_renewal').download)?_c('CButton',{staticClass:"float-lg-right",staticStyle:{"margin-right":"10px"},attrs:{"size":"sm","color":"primary"},on:{"click":_vm.download}},[_c('i',{staticClass:"fa fa-download"}),_vm._v("Download ")]):_vm._e()],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('CCollapse',{staticClass:"mt-2",attrs:{"id":"collapse1","show":_vm.showCollapse}},[_c('CCard',{staticClass:"p-4"},[_c('Search',{on:{"depot-search-query":_vm.search}})],1)],1)],1)],1),_c('hr'),_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('div',{staticClass:"table-responsive-sm table-responsive-md"},[_c('CTabs',{attrs:{"variant":"pills","active-tab":0}},[_c('CTab',{attrs:{"title":"All"}},[_c('br'),_c('CDataTable',{attrs:{"loading":_vm.isLoading,"items":_vm.license_renewal_list_all.data,"fields":_vm.fields,"hover":"","striped":"","outlined":""},scopedSlots:_vm._u([{key:"due_date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px"}},[_vm._v(" "+_vm._s(_vm._f("date_format")(item.due_date,'MMMM DD, YYYY'))+" "),_c('br'),(item.time != null)?_c('CBadge',{attrs:{"color":_vm._f("badgeColor")(item.due_date,item.time, item.threshold_unit)}},[_c('small',[_vm._v(_vm._s(_vm._f("badgeText")(item.due_date))+" ")])]):_vm._e()],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","vertical-align":"inherit"}},[_c('CButton',{staticStyle:{"width":"74px"},attrs:{"size":"sm","color":_vm._f("dueDateStatusColor")(item.due_date,item.time,item.threshold_unit)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","vertical-align":"inherit"}},[_c('CDropdown',{attrs:{"toggler-text":"Select","size":"sm","color":"info"}},[(_vm.config.getPermission('license_renewal').view)?_c('CDropdownItem',{on:{"click":function($event){return _vm.viewRenewal(item)}}},[_c('i',{staticClass:"fa fa-eye"},[_vm._v(" View ")])]):_vm._e(),(_vm.config.getPermission('license_renewal').update)?_c('CDropdownItem',{on:{"click":function($event){return _vm.updateRenewal(item)}}},[_c('i',{staticClass:"fa fa-pencil-square-o"},[_vm._v(" Update ")])]):_vm._e(),_c('CDropdownItem',{on:{"click":function($event){return _vm.sendEmail(item)}}},[_c('div',[_c('div',[_c('i',{staticClass:"fa fa-bell"},[_vm._v(" Notify ")])])])]),(_vm.config.getPermission('license_renewal').delete)?_c('CDropdownItem',{on:{"click":function($event){return _vm.deleteRow(item)}}},[_c('i',{staticClass:"fa fa-trash"},[_vm._v(" Delete ")])]):_vm._e()],1)],1)]}}])}),_c('label',{staticStyle:{"float":"right"}},[_vm._v("Showing "+_vm._s(_vm.license_renewal_list_all.from)+" to "+_vm._s(_vm.license_renewal_list_all.to)+" of "+_vm._s(_vm.license_renewal_list_all.total)+" results")]),_c('CPagination',{attrs:{"active-page":_vm.currentPageAll,"pages":Math.ceil(_vm.countDataAll / 20),"activePage":_vm.currentPageAll},on:{"update:activePage":[function($event){_vm.currentPageAll=$event},_vm.updatePageAll],"update:active-page":function($event){_vm.currentPageAll=$event}}})],1),_c('CTab',{attrs:{"title":"Due Soon"}},[_c('br'),_c('CDataTable',{attrs:{"loading":_vm.isLoading,"items":_vm.license_renewal_list_due_soon.data,"fields":_vm.fields,"hover":"","striped":"","outlined":""},scopedSlots:_vm._u([{key:"due_date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px"}},[_vm._v(" "+_vm._s(_vm._f("date_format")(item.due_date,'MMMM DD, YYYY'))+" "),_c('br'),(item.time != null)?_c('CBadge',{attrs:{"color":_vm._f("badgeColor")(item.due_date,item.time, item.threshold_unit)}},[_c('small',[_vm._v(_vm._s(_vm._f("badgeText")(item.due_date))+" ")])]):_vm._e()],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","vertical-align":"inherit"}},[_c('CButton',{staticStyle:{"width":"74px"},attrs:{"size":"sm","color":_vm._f("dueDateStatusColor")(item.due_date,item.time,item.threshold_unit)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","vertical-align":"inherit"}},[_c('CDropdown',{attrs:{"toggler-text":"Select","size":"sm","color":"info"}},[(_vm.config.getPermission('license_renewal').view)?_c('CDropdownItem',{on:{"click":function($event){return _vm.viewRenewal(item)}}},[_c('i',{staticClass:"fa fa-eye"},[_vm._v(" View ")])]):_vm._e(),(_vm.config.getPermission('license_renewal').update)?_c('CDropdownItem',{on:{"click":function($event){return _vm.updateRenewal(item)}}},[_c('i',{staticClass:"fa fa-pencil-square-o"},[_vm._v(" Update ")])]):_vm._e(),_c('CDropdownItem',{on:{"click":function($event){return _vm.sendEmail(item)}}},[_c('div',[_c('div',[_c('i',{staticClass:"fa fa-bell"},[_vm._v(" Notify ")])])])]),(_vm.config.getPermission('license_renewal').delete)?_c('CDropdownItem',{on:{"click":function($event){return _vm.deleteRow(item)}}},[_c('i',{staticClass:"fa fa-trash"},[_vm._v(" Delete ")])]):_vm._e()],1)],1)]}}])}),_c('label',{staticStyle:{"float":"right"}},[_vm._v("Showing "+_vm._s(_vm.license_renewal_list_due_soon.from)+" to "+_vm._s(_vm.license_renewal_list_due_soon.to)+" of "+_vm._s(_vm.license_renewal_list_due_soon.total)+" results")]),_c('CPagination',{attrs:{"active-page":_vm.currentPageDueSoon,"pages":Math.ceil(_vm.countDataDueSoon / 20),"activePage":_vm.currentPageDueSoon},on:{"update:activePage":[function($event){_vm.currentPageDueSoon=$event},_vm.updatePageDueSoon],"update:active-page":function($event){_vm.currentPageDueSoon=$event}}})],1),_c('CTab',{attrs:{"title":"Overdue"}},[_c('br'),_c('CDataTable',{attrs:{"loading":_vm.isLoading,"items":_vm.license_renewal_list_overdue.data,"fields":_vm.fields,"hover":"","striped":"","outlined":""},scopedSlots:_vm._u([{key:"due_date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px"}},[_vm._v(" "+_vm._s(_vm._f("date_format")(item.due_date,'MMMM DD, YYYY'))+" "),_c('br'),(item.time != null)?_c('CBadge',{attrs:{"color":_vm._f("badgeColor")(item.due_date,item.time, item.threshold_unit)}},[_c('small',[_vm._v(_vm._s(_vm._f("badgeText")(item.due_date))+" ")])]):_vm._e()],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","vertical-align":"inherit"}},[_c('CButton',{staticStyle:{"width":"74px"},attrs:{"size":"sm","color":_vm._f("dueDateStatusColor")(item.due_date,item.time,item.threshold_unit)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","vertical-align":"inherit"}},[_c('CDropdown',{attrs:{"toggler-text":"Select","size":"sm","color":"info"}},[(_vm.config.getPermission('license_renewal').view)?_c('CDropdownItem',{on:{"click":function($event){return _vm.viewRenewal(item)}}},[_c('i',{staticClass:"fa fa-eye"},[_vm._v(" View ")])]):_vm._e(),(_vm.config.getPermission('license_renewal').update)?_c('CDropdownItem',{on:{"click":function($event){return _vm.updateRenewal(item)}}},[_c('i',{staticClass:"fa fa-pencil-square-o"},[_vm._v(" Update ")])]):_vm._e(),_c('CDropdownItem',{on:{"click":function($event){return _vm.sendEmail(item)}}},[_c('div',[_c('div',[_c('i',{staticClass:"fa fa-bell"},[_vm._v(" Notify ")])])])]),(_vm.config.getPermission('license_renewal').delete)?_c('CDropdownItem',{on:{"click":function($event){return _vm.deleteRow(item)}}},[_c('i',{staticClass:"fa fa-trash"},[_vm._v(" Delete ")])]):_vm._e()],1)],1)]}}])}),_c('label',{staticStyle:{"float":"right"}},[_vm._v("Showing "+_vm._s(_vm.license_renewal_list_overdue.from)+" to "+_vm._s(_vm.license_renewal_list_overdue.to)+" of "+_vm._s(_vm.license_renewal_list_overdue.total)+" results")]),_c('CPagination',{attrs:{"active-page":_vm.currentPageOverdue,"pages":Math.ceil(_vm.countDataOverdue / 20),"activePage":_vm.currentPageOverdue},on:{"update:activePage":[function($event){_vm.currentPageOverdue=$event},_vm.updatePageOverdue],"update:active-page":function($event){_vm.currentPageOverdue=$event}}})],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }